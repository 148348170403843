import { cancelProgramApi } from "@/api/parentsApi";
import { atomsWithMutation } from "jotai-tanstack-query";
import { atomWithReset, atomWithStorage, createJSONStorage } from "jotai/utils";
import { AsyncStorage } from "jotai/vanilla/utils/atomWithStorage";

import {
    UserInterface,
    UserLoginInfoInterface
} from "@ts/interfaces/Parent/UserInterface";

export const INITIAL_USER_STATE = {
    id: 0,
    accountType: "",
    email: "",
    is_enrolled: false,
    name: "",
    grade: "",
    displayName: "",
    auth0_username: "",
    firstName: "",
    lastName: "",
    status: "",
    password: "",
    auth0Id: "",
    chargebeeId: "",
    chargebee_id: "",
    hubspotId: "",
    wpEcomUserId: "",
    wp_lms_user_id: 0,
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    pendingStudentEnrolment: false,
    lastUpdatedAt: "",
    license_expiry_at_text: "",
    has_expired_license: true,
    student_grade: {
        grade: -1,
        id: -1,
        long_name: ""
    }
} as UserInterface;

export const userAtom = atomWithReset<UserInterface>(INITIAL_USER_STATE);

const localStorageForAtom = createJSONStorage(() => localStorage);

export const [, cancelProgramAtom] = atomsWithMutation(() => ({
    mutationKey: ["cancelProgram"],
    mutationFn: async (data: {
        type: "addon" | "plan";
        licenseId?: number;
        itemId?: number;
        studentId: number;
        reasonId: number | null;
        cancellationType: "refund" | "non-refund";
    }) => {
        return (await cancelProgramApi(data)).data;
    }
}));

export const userLoginAtom = atomWithStorage<UserLoginInfoInterface>(
    "parentLoginInfo",
    {
        userName: "",
        rememberMe: false
    },
    localStorageForAtom as AsyncStorage<UserLoginInfoInterface>,
    { unstable_getOnInit: true }
);

// Defining debug info for the atoms created to be consumed by the Jotai DevTools
userAtom.debugLabel = "userAtom";
userLoginAtom.debugLabel = "userLoginAtom";
